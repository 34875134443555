export type Enum<TEnum extends object> = TEnum[keyof TEnum];

export const TENANT_SUBSCRIPTIONL_PLAN = {
    STARTER: 'starter',
    PRO: 'pro',
    ENTERPRISE: 'enterprise',
    GROWTH: 'growth',
    COMBO_ESSENTIAL: 'combo-essential',
    COMBO_PRO: 'combo-pro',
    COMBO_ENTERPRISE: 'combo-enterprise',
} as const;

export type SubscriptionPlan = Enum<typeof TENANT_SUBSCRIPTIONL_PLAN>;
